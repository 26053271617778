import { useEffect, useState } from "react";

import { authentication } from "@microsoft/teams-js";
import { IAnswerFeedback, IAnswerFeedbackResponse } from "../types/answer";

import { useTranslation } from "react-i18next";
import axios from "axios";

import AvatarGroup from "./AvatarGroup";
import { Person } from "@microsoft/mgt-react";
import {
  Avatar,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FEEDBACK_TYPE_LIST } from "../constants/feedbackTypes";

interface IAnswerCardsProps {
  channelId: string;
}

const AnswerCards: React.FC<IAnswerCardsProps> = ({ channelId }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [items, setItems] = useState<IAnswerFeedback[]>([]);

  useEffect(() => {
    setItems([]);
    setIsLoading(true);
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IAnswerFeedbackResponse>(`/Answers/GetAnswerFeedback`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              channelId,
            },
          })
          .then((res) => {
            setIsLoading(false);
            setItems(res.data.answerFeedbackList);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err:", err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err:", err);
      });
  }, [channelId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (items.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h5">{t("answerCard.noAnswers")}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      {items.map((item, index) => (
        <Grid key={`answerCard${index}`} item xs={12} sm={6} md={4} xl={3}>
          <Paper
            style={{
              height: "100%",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h5">
                  {t("answerCard.correctGuesses", {
                    percent: item.quizzesCorrectPercent,
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: -8,
                }}
              >
                <Typography variant="subtitle2">
                  {t("answerCard.timesCompleted", {
                    count: item.answerCompletedInQuizzes,
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">
                  <b>{t("answerCard.question")}</b> {item.questionText}
                </Typography>
              </Grid>

              <Grid item>
                {item.answerText ? (
                  <Typography variant="body2">
                    <b>{t("answerCard.yourAnswer")}</b> {item.answerText}
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    {t("answerCard.noAnswer")}
                  </Typography>
                )}
              </Grid>
              {item.usersWithCorrectGueses || item.feedbackList ? (
                <Grid container spacing={2}>
                  {item.usersWithCorrectGueses &&
                    item.usersWithCorrectGueses.length > 0 && (
                      <Grid
                        container
                        item
                        direction="column"
                        xs={12}
                        lg={6}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography variant="subtitle2">
                            {t("answerCard.correctGuessesGivenBy")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <AvatarGroup
                            items={item.usersWithCorrectGueses.map((user) => {
                              return {
                                key: user.aadObjectId,
                                tooltip: user.displayName,
                                avatar: (
                                  <Person
                                    userId={user.aadObjectId}
                                    avatarSize="large"
                                  />
                                ),
                              };
                            })}
                          />
                        </Grid>
                      </Grid>
                    )}
                  {item.feedbackList && item.feedbackList.length > 0 && (
                    <Grid container item direction="column" xs={12} lg={6}>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {t("answerCard.recognitionsReceived")}
                        </Typography>
                      </Grid>
                      <Grid container item>
                        {item.feedbackList.map((feedback) => {
                          return (
                            <Grid item>
                              <Tooltip
                                key={feedback.feedbackType}
                                arrow
                                title={
                                  <Grid
                                    container
                                    direction="column"
                                    spacing={1}
                                  >
                                    {feedback.feedbackUserList.map((user) => (
                                      <Grid
                                        container
                                        item
                                        spacing={1}
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Avatar>
                                            <Person
                                              userId={user.aadObjectId}
                                              avatarSize="large"
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            variant="body2"
                                            style={{ color: "#fff" }}
                                          >
                                            {user.displayName}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                }
                              >
                                <Chip
                                  label={`${
                                    FEEDBACK_TYPE_LIST[feedback.feedbackType]
                                      .emoji
                                  }x${feedback.feedbackCount}`}
                                />
                              </Tooltip>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default AnswerCards;
