import type {} from "@mui/x-data-grid/dist/data-grid";
// import type {} from "@mui/lab/themeAugmentation";

// import { createTheme } from "@mui/material/styles";

// import { grey } from "@mui/material/colors";

import { createTheme } from "@material-ui/core/styles";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#F47923",
    },
    text: {
      primary: "#1C1917",
    },
  },
  typography: {
    fontFamily: "Roboto Flex",
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 28,
        textTransform: "none",
      },
      contained: {
        backgroundColor: "#1C1917",
        color: "#fff",
      },
    },
    MuiTypography: {
      root: {
        color: "#1C1917",
      },
      h1: {
        fontSize: 54,
        fontWeight: 700,
        fontFamily: "DM Sans",
      },
      h2: {
        fontSize: 48,
        fontWeight: 700,
        fontFamily: "DM Sans",
      },
      h5: {
        fontSize: 24,
        fontWeight: 700,
        fontFamily: "DM Sans",
      },
      body1: {
        fontFamily: "DM Sans",
        fontSize: 24,
      },
      subtitle2: {
        fontSize: 14,
      },
      body2: {
        fontSize: 18,
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: 28,
        overflow: "hidden",
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 28,
        borderTopRightRadius: 28,
      },
    },
    MuiSelect: {
      filled: {
        backgroundColor: "#1C1917",
        color: "#fff",
        paddingTop: 18,
        paddingBottom: 18,
      },
      icon: {
        color: "#fff",
      },
    },
    MuiDataGrid: {
      root: {
        border: "none",
        "& .MuiDataGrid-window": {
          backgroundColor: "#fff",
          border: "1px solid #1C1917",
          borderBottom: "none",
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        },
        "& .MuiDataGrid-columnsContainer": {
          border: "none !important",
          marginLeft: -8,
        },
        "& .MuiDataGrid-dataContainer": {
          marginLeft: -2,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "#fff",
          border: "1px solid #1C1917 !important",
          borderBottomLeftRadius: 25,
          borderBottomRightRadius: 25,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontFamily: "DM Sans",
          color: "#78716C",
          fontSize: 24,
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#fff",
        border: "1px solid #1C1917",
        borderRadius: "25px !important",
        padding: "12px 16px",
        overflow: "hidden",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#1C1917",
        color: "#FCFCFC",
        fontFamily: "Roboto Flex",
        fontSize: 18,
      },
    },
  },
});

export default baseTheme;
