import { useEffect, useState } from "react";

import { Link, Link as RouterLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { track } from "@amplitude/analytics-browser";

import _ from "lodash";

import moment from "moment";

import { Grid, Stack, FormControlLabel, Switch, Box } from "@mui/material";
import CourseCard from "../../components/CourseCard";
import { IUserCourse } from "../../types/course";
import { Typography } from "@material-ui/core";

export default function MyCourses() {
  const { t } = useTranslation();

  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [data, setData] = useState<IUserCourse[]>([
    {
      id: 1,
      contentName: "Kiberdrošība",
      teamName: "Test",
      endDate: moment().add(30, "days").toDate(),
      active: true,
    },
    {
      id: 2,
      contentName: "Effective Communicaiton",
      teamName: "Test",
      endDate: moment().add(30, "days").toDate(),
      active: true,
    },
    {
      id: 3,
      contentName: "Effective Meetings",
      teamName: "Test",
      endDate: moment().add(30, "days").toDate(),
      active: true,
    },
    {
      id: 4,
      contentName: "Employee Wellbeing",
      teamName: "Test",
      active: true,
    },
    {
      id: 5,
      contentName: "GDPR",
      teamName: "Test",
      active: true,
    },
    {
      id: 6,
      contentName: "Vēl kkas random",
      teamName: "Test",
      active: true,
    },
    {
      id: 3,
      contentName: "Cybersecurity",
      teamName: "Test",
      endDate: moment().subtract(10, "days").toDate(),
      active: false,
    },
  ]);

  useEffect(() => {
    track("OpenMyCourses");
  }, []);

  return (
    <Stack style={{ paddingBottom: 16 }} spacing={2}>
      <Typography variant="h2" component="h1">
        {t("texts.myCourses", "My courses")}
      </Typography>

      <Box>
        <FormControlLabel
          control={
            <Switch
              id="showOnlyActive"
              color="warning"
              checked={showOnlyActive}
              onChange={() => {
                setShowOnlyActive(!showOnlyActive);
              }}
            />
          }
          label="See active courses only"
        />
      </Box>

      <Box>
        <Grid container spacing={2}>
          {data
            .filter((item) => (showOnlyActive ? item.active : true))
            .map((item) => (
              <Grid key={item.id} item xs={4} md={3} lg={2} flexGrow={1}>
                <RouterLink
                  to={`/courses/${item.id}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <CourseCard item={item} />
                </RouterLink>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Stack>
  );
}
