import i18n from "../i18n";

export default {
  productivity: {
    title: i18n.t("prompts.productivity.title"),
    prompts: [
      i18n.t("prompts.productivity.prompts.1"),
      i18n.t("prompts.productivity.prompts.2"),
      i18n.t("prompts.productivity.prompts.3"),
      i18n.t("prompts.productivity.prompts.4"),
      i18n.t("prompts.productivity.prompts.5"),
    ],
  },
  creativity: {
    title: i18n.t("prompts.creativity.title"),
    prompts: [
      i18n.t("prompts.creativity.prompts.1"),
      i18n.t("prompts.creativity.prompts.2"),
      i18n.t("prompts.creativity.prompts.3"),
      i18n.t("prompts.creativity.prompts.4"),
      i18n.t("prompts.creativity.prompts.5"),
    ],
  },
  wellness: {
    title: i18n.t("prompts.wellness.title"),
    prompts: [
      i18n.t("prompts.wellness.prompts.1"),
      i18n.t("prompts.wellness.prompts.2"),
      i18n.t("prompts.wellness.prompts.3"),
      i18n.t("prompts.wellness.prompts.4"),
      i18n.t("prompts.wellness.prompts.5"),
    ],
  },
  cybersecurity: {
    title: i18n.t("prompts.cybersecurity.title"),
    prompts: [
      i18n.t("prompts.cybersecurity.prompts.1"),
      i18n.t("prompts.cybersecurity.prompts.2"),
      i18n.t("prompts.cybersecurity.prompts.3"),
      i18n.t("prompts.cybersecurity.prompts.4"),
      i18n.t("prompts.cybersecurity.prompts.5"),
    ],
  },
  leadership: {
    title: i18n.t("prompts.leadership.title"),
    prompts: [
      i18n.t("prompts.leadership.prompts.1"),
      i18n.t("prompts.leadership.prompts.2"),
      i18n.t("prompts.leadership.prompts.3"),
      i18n.t("prompts.leadership.prompts.4"),
      i18n.t("prompts.leadership.prompts.5"),
    ],
  },
};
