import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { track } from "@amplitude/analytics-browser";

import _ from "lodash";
import { Button, Grid } from "@material-ui/core";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

export default function Prompts() {
  const { t } = useTranslation();

  useEffect(() => {
    track("OpenAdminTab");
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{
          height: "100%",
        }}
      >
        <Grid item xs={4}>
          <img
            src="./assets/rafiki.png"
            alt="Admin"
            style={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item>
          <a href="https://hub.ervy.app" target="_blank">
            <Button
              variant="contained"
              startIcon={<PersonOutlineOutlinedIcon />}
            >
              {t("buttons.openHub")}
            </Button>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
