import i18n from "../i18n";

export enum FeedbackType {
  COOL = "Cool",
  SURPRISING = "Surprising",
  WITTY = "Witty",
}

interface IFeedbackType {
  emoji: string;
  name: string;
}

type FeedbackTypeList = {
  [key in FeedbackType]: IFeedbackType;
};

export const FEEDBACK_TYPE_LIST: FeedbackTypeList = {
  [FeedbackType.COOL]: {
    emoji: "😎",
    name: i18n.t("feedbackType.cool"),
  },
  [FeedbackType.SURPRISING]: {
    emoji: "😮",
    name: i18n.t("feedbackType.surprising"),
  },
  [FeedbackType.WITTY]: {
    emoji: "😄",
    name: i18n.t("feedbackType.witty"),
  },
};
