import { useEffect } from "react";
import { chat, app } from "@microsoft/teams-js";

import { useTranslation } from "react-i18next";

import { track } from "@amplitude/analytics-browser";

import PROMPTS from "../constants/prompts";
import _ from "lodash";
import { Grid } from "@material-ui/core";

export default function Prompts() {
  const { t } = useTranslation();

  const sendPrompt = async (prompt: string) => {
    try {
      const context = await app.getContext();
      console.log(context);

      chat.openChat({
        user: `28:${process.env.REACT_APP_CLIENT_ID}`,
        message: prompt,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    track("OpenHomeTab");
  }, []);

  return (
    <div>
      <h1>{t("texts.home")}</h1>

      {_.map(PROMPTS, (prompt, categoryIndex) => {
        return (
          <Grid key={`prompt.${categoryIndex}`} style={{ marginTop: 32 }}>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {/* <Text size={500}>{t(prompt.title)}</Text> */}
            </Grid>
            <Grid item xs={12} container spacing={2}>
              {prompt.prompts.map((item, itemIndex) => (
                <Grid
                  key={`prompt.${categoryIndex}.${itemIndex}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  {/* <Card
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => sendPrompt(item)}
                  >
                    <Text>{t(item)}</Text>
                  </Card> */}
                </Grid>
              ))}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}
