import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import "./index.css";

import "moment/locale/lv";
import "moment/locale/et";
import "moment/locale/lt";
import "moment/locale/ro";

ReactDOM.render(<App />, document.getElementById("root"));
