import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { track } from "@amplitude/analytics-browser";

import _ from "lodash";

import moment from "moment";

import { Grid, Stack, Box } from "@mui/material";

import { ILesson, IUserCourse } from "../../types/course";
import {
  Chip,
  IconButton,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import Chevron from "../../components/icons/Chevron";
import { Link } from "react-router-dom";
import Calendar from "../../components/icons/Calendar";
import Hash from "../../components/icons/Hash";
import CircleFilled from "../../components/icons/CircleFilled";

import LessonCard from "../../components/LessonCard";

export default function Course() {
  const { t } = useTranslation();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [data, setData] = useState<
    IUserCourse & {
      lessons: ILesson[];
    }
  >({
    id: 1,
    contentName: "Kiberdrošība",
    teamName: "Test",
    endDate: moment().add(30, "days").toDate(),
    active: true,
    lessons: [
      {
        lessonId: 1942,
        questionCategoryId: 344,
        createdOn: moment().toDate(),
        question: "Kas ir IoT?",
        answers: [
          {
            id: 38799,
            text: 'Ierīču kategorija, ko apzīmē ar "Internet of Things" un kas ietver ļoti plašu specializētu iekārtu kopu.',
          },
          {
            id: 38800,
            text: "Simbolu virkne, ko ievadot Whatsapp lietotnē tiek parādīts izbrīnītas sejas emoji",
          },
          {
            id: 38801,
            text: "Internal operations Techonolgy - sistēmu apzīmējums, ko lieto iekštīklā izvietotām sistēmām",
          },
          {
            id: 38802,
            text: "Innovative Technology - vispārīgs apzīmējums, kas norāda uz augstas pievienotās vērtības produktu",
          },
        ],
      },
      {
        lessonId: 1943,
        questionCategoryId: 344,
        createdOn: moment().toDate(),
        question: "Kas ir IoT?",
        answers: [
          {
            id: 38799,
            text: 'Ierīču kategorija, ko apzīmē ar "Internet of Things" un kas ietver ļoti plašu specializētu iekārtu kopu.',
          },
          {
            id: 38800,
            text: "Simbolu virkne, ko ievadot Whatsapp lietotnē tiek parādīts izbrīnītas sejas emoji",
          },
          {
            id: 38801,
            text: "Internal operations Techonolgy - sistēmu apzīmējums, ko lieto iekštīklā izvietotām sistēmām",
          },
          {
            id: 38802,
            text: "Innovative Technology - vispārīgs apzīmējums, kas norāda uz augstas pievienotās vērtības produktu",
          },
        ],
      },
      {
        lessonId: 1945,
        questionCategoryId: 344,
        createdOn: moment().toDate(),
        question: "Kas ir IoT?",
        answers: [
          {
            id: 38799,
            text: 'Ierīču kategorija, ko apzīmē ar "Internet of Things" un kas ietver ļoti plašu specializētu iekārtu kopu.',
          },
          {
            id: 38800,
            text: "Simbolu virkne, ko ievadot Whatsapp lietotnē tiek parādīts izbrīnītas sejas emoji",
          },
          {
            id: 38801,
            text: "Internal operations Techonolgy - sistēmu apzīmējums, ko lieto iekštīklā izvietotām sistēmām",
          },
          {
            id: 38802,
            text: "Innovative Technology - vispārīgs apzīmējums, kas norāda uz augstas pievienotās vērtības produktu",
          },
        ],
      },
      {
        lessonId: 1941,
        questionCategoryId: 344,
        isCorrect: true,
        createdOn: moment().toDate(),
        answerGivenId: 38794,
        question: "Ko nozīmē mazāko iespējamo piekļuves tiesību princips?",
        answers: [
          {
            id: 38796,
            text: "Atļaut lietotājiem definēt tiem nepieciešāmās piekļuves",
            isCorrect: false,
          },
          {
            id: 38797,
            text: "Dot piekļuvi visiem pie visām sistēmām",
            isCorrect: false,
          },
          {
            id: 38798,
            text: "Ierobežot piekļuvi tikai vissvarīgākām sistēmām",
            isCorrect: false,
          },
          {
            id: 38794,
            text: "Piešķirt mazāko itesību kopu lietotājiem, kas nepieciešams to darba pienākumu veikšanai",
            isCorrect: true,
          },
        ],
      },
    ],
  });

  useEffect(() => {
    track("OpenCourse");
  }, []);

  return (
    <Stack style={{ paddingBottom: 16 }} spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Link to="/courses">
          <IconButton>
            <Chevron
              style={{
                transform: "rotate(270deg)",
              }}
            />
          </IconButton>
        </Link>
        <Typography variant="h2" component="h1">
          {data.contentName}
        </Typography>
      </Stack>

      <Box>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="h5" component="h2">
              {t("texts.statistics", "Statistics")}
            </Typography>
            <Paper
              style={{
                border: "none",
                padding: 24,
              }}
              elevation={0}
            >
              <Stack direction="row">
                <Stack flex={1} spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      label="Instant"
                      style={{
                        backgroundColor: "#924915",
                      }}
                    />
                    <Typography>training</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Calendar width={48} height={48} />
                    <Typography>
                      {moment().format("L")} -{" "}
                      {moment().add(10, "days").format("L")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Hash width={48} height={48} />
                    <Typography>14 lessons</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <CircleFilled color="green" />
                    <Typography>Active</Typography>
                  </Stack>
                </Stack>
                <Stack flex={1}>O</Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" component="h2">
          {t("texts.lessons", "Lessons")}
        </Typography>
        <Grid container spacing={4}>
          {_.zip(..._.chunk(data.lessons, smDown ? 1 : mdDown ? 2 : 3)).map(
            (column, index) => (
              <Grid
                key={index}
                container
                item
                xs
                spacing={4}
                direction="column"
                role="column"
              >
                {column.map((lesson) =>
                  lesson ? (
                    <Grid key={lesson.lessonId} item>
                      <LessonCard lesson={lesson} />
                    </Grid>
                  ) : null
                )}
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </Stack>
  );
}
