import { ReactElement } from "react";

import _ from "lodash";

import { Avatar, Grid, Tooltip, Typography } from "@material-ui/core";
import { AvatarGroup as MuiAvatarGroup } from "@material-ui/lab";

interface IAvatar {
  key: string;
  tooltip: string;
  avatar: ReactElement;
}

interface IAvatarGroupProps {
  items: IAvatar[];
  showMax?: number;
}

const AvatarGroup: React.FC<IAvatarGroupProps> = ({ items, showMax = 5 }) => {
  return (
    <MuiAvatarGroup max={items.length}>
      {_.slice<IAvatar>(items, 0, items.length > showMax ? showMax - 1 : items.length).map(
        (item: IAvatar, index) => (
          <Tooltip
            arrow
            key={item.key}
            title={
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Avatar>{item.avatar}</Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    {item.tooltip}
                  </Typography>
                </Grid>
              </Grid>
            }
          >
            <Avatar>{item.avatar}</Avatar>
          </Tooltip>
        )
      )}
      {items.length > showMax ? (
        <Tooltip
          arrow
          title={
            <Grid container direction="column" spacing={1}>
              {_.slice<IAvatar>(items, showMax - 1).map((item: IAvatar) => (
                <Grid item container key={item.key} spacing={1} alignItems="center">
                  <Grid item>
                    <Avatar>{item.avatar}</Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: "#fff" }}>
                      {item.tooltip}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        >
          <Avatar>+{items.length - showMax + 1}</Avatar>
        </Tooltip>
      ) : null}
    </MuiAvatarGroup>
  );
};

export default AvatarGroup;
